import React, { useEffect, useState } from 'react';
import {Form, Input, Button, notification, Row, Col, Select} from 'antd';
import { useParams } from 'react-router-dom';
import { activesApi } from '../apiCalls/activesApi';

const { Option } = Select;

const ActiveFormEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchActive = async () => {
      setLoading(true);
      const active = await activesApi.getActiveById(id!);
      if (active) {
        form.setFieldsValue({
          name: active.name,
          vrn: active.vrn,
          vrnUnit: active.vrnUnit,
          iuClaim: active.iuClaim,
          ul: active.ul,
          ulUnit: active.ulUnit,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Active not found.',
          duration: 3,
        });
      }
      setLoading(false);
    };

    fetchActive();
  }, [id, form]);

  const onFinish = async (values: any) => {
    const { vrn, vrnUnit, iuClaim, ul, ulUnit } = values;
    const activeData = {
      vrn: vrn ? Number(vrn) : null,
      vrnUnit,
      iuClaim: iuClaim ? Number(iuClaim) : null,
      ul: ul ? Number(ul) : null,
      ulUnit,
    };

    const result = await activesApi.updateActive(id!, activeData);
    if (result) {
      notification.success({
        message: 'Active Updated',
        description: 'The active has been successfully updated.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error updating',
        description: 'There was an error updating the asset.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Edit Active</h2>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Enter the name" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="VRN" name="vrn">
                <Input placeholder="Enter VRN" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="VRN Unit"
                  name="vrnUnit"
                  rules={[{ required: true, message: 'Please select the VRN unit' }]}
              >
                <Select placeholder="Select VRN Unit">
                  <Option value="mg">mg</Option>
                  <Option value="g">g</Option>
                  <Option value="%">%</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="I.U. Claim" name="iuClaim">
                <Input placeholder="Enter I.U. Claim" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="UL" name="ul">
                <Input placeholder="Enter UL" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="UL Unit"
                  name="ulUnit"
                  rules={[{ required: true, message: 'Please select the UL unit' }]}
              >
                <Select placeholder="Select UL Unit">
                  <Option value="mg/day">mg/day</Option>
                  <Option value="g/day">g/day</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Active
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default ActiveFormEdit;
