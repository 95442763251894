import React from 'react';
import {Form, Input, Button, notification, Row, Col, Select} from 'antd';
import { activesApi } from '../apiCalls/activesApi';

const { Option } = Select;

const AddActiveForm: React.FC = () => {
  const onFinish = async (values: any) => {
    const { name, vrn, vrnUnit, iuClaim, ul, ulUnit } = values;
    const activeData = {
      name,
      vrn: vrn ? Number(vrn) : null,
      vrnUnit,
      iuClaim: iuClaim ? Number(iuClaim) : null,
      ul: ul ? Number(ul) : null,
      ulUnit,
    };

    const result = await activesApi.createActive(activeData);
    if (result) {
      notification.success({
        message: 'Active Created',
        description: 'The active has been created successfully.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error al Crear',
        description: 'Hubo un error al crear el activo.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Add new active</h2>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="VRN" name="vrn">
                <Input placeholder="Enter VRN" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="VRN Unit"
                  name="vrnUnit"
                  rules={[{ required: true, message: 'Please select the VRN unit' }]}
              >
                <Select placeholder="Select VRN Unit">
                  <Option value="mg">mg</Option>
                  <Option value="g">g</Option>
                  <Option value="%">%</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="I.U. Claim" name="iuClaim">
                <Input placeholder="Enter I.U. Claim" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="UL" name="ul">
                <Input placeholder="Enter UL" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  label="UL Unit"
                  name="ulUnit"
                  rules={[{ required: true, message: 'Please select the UL unit' }]}
              >
                <Select placeholder="Select UL Unit">
                  <Option value="mg/day">mg/day</Option>
                  <Option value="g/day">g/day</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Active
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default AddActiveForm;
