import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, Spin, Tag, Button, Input, Space, TableColumnType, Tooltip} from 'antd';
import CustomTable from '../components/CustomTable';
import {IngredientDataType} from '../models/column';
import {ColumnsType} from 'antd/es/table';
import {useNavigate} from 'react-router-dom';
import {productsApi} from '../apiCalls/productsApi';
import {EditOutlined, SearchOutlined} from '@ant-design/icons';
import type {FilterDropdownProps} from 'antd/es/table/interface';

const PAGE_SIZE = 50;

const CustomFormTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState<ColumnsType<IngredientDataType>>([]);
  const [dataSource, setDataSource] = useState<IngredientDataType[]>([]);

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const searchInput = useRef<any>(null);

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({closeDropdown: false});
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
              close();
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const products = await productsApi.getProducts();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const allKeys = [...new Set(products.flatMap(Object.keys))].filter((key) => key !== 'id');

          const mediumColumns = ['quantity', 'price'];
          const widerColumns = ['name'];

          const capitalizeFirstLetter = (string: string) => {
          if (string === 'umbrellaId') {
            return 'ID';
          }

          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        setColumn([
          {
            title: 'Edit',
            key: 'operation',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fixed: 'left',
            width: 75,
            align: 'center',
            render: (value) => (
              <a
                  onClick={() => {
                      navigate(`/edit-product/${value.id}`);
                  }}
              >
                <EditOutlined />
              </a>
            ),
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...allKeys.map((item) => {
            return {
              title: capitalizeFirstLetter(item),
              key: item,
              dataIndex: item,
              width:
                  widerColumns.includes(item)
                      ? 250
                      : mediumColumns.includes(item)
                          ? 150
                          : 100,
              fixed: item === 'name' || item === 'umbrellaId' ? 'left' : undefined,
              ...(item === 'name' || item === 'umbrellaId' ? getColumnSearchProps(item) : {}),
              align: 'center',
              render: (value: any) => {
                if (typeof value === 'boolean') {
                  return <Checkbox checked={value} />;
                }

                if (value?.name) {
                  return value.name;
                }

                if (Array.isArray(value) && value.length > 0) {
                  if (value[0]?.name) {
                    return value.map((item) => {
                      return (
                        <Tag color="blue" key={item.id}>
                          {item?.name?.length > 20 ? item?.name?.substring(0, 18) + '...' : item?.name}
                        </Tag>
                      );
                    });
                  } else {
                    if (item === 'actives') {
                      const toolTipContent = value.map((item, index) => {
                        const text = `•${item?.activeName?.name} ${item.activeValue}%`;
                        return (
                          <div
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '1000px',
                            }}
                          >
                            {text}
                          </div>
                        );
                      });

                      return (
                        <Tooltip title={<span>{toolTipContent}</span>} overlayStyle={{maxWidth: 1000}}>
                          <Tag color="blue" key={item}>
                            {value.length === 1 ? 'Hay 1 valor' : `Hay ${value.length} valores`}
                          </Tag>
                        </Tooltip>
                      );
                    }

                    return (
                      <Tag color="blue" key={item}>
                        {value.length === 1 ? 'Hay 1 valor' : `Hay ${value.length} valores`}
                      </Tag>
                    );
                  }
                }

                return value;
              },
            };
          }),
        ]);

        const data = products.map((item: any) => ({
          ...item,
          id: item.id,
        }));

        setDataSource(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  return (
    <div>
      <div style={{marginLeft: 50, marginTop: 30}}>
        <h2 style={{marginBottom: 20}}>Products</h2>
      </div>

      <div style={{marginLeft: 50, marginRight: 50}}>
        <CustomTable
          pagination={{defaultPageSize: PAGE_SIZE, pageSizeOptions: [10, 25, 50]}}
          columns={column}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

export default CustomFormTable;
