import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Spin, Table} from 'antd';
import {IngredientDataType} from '../models/column';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {ColumnsType} from 'antd/es/table';
import {EditOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const Providers: React.FC = () => {
  const [dataSource, setDataSource] = useState<IngredientDataType[]>([]);
  const [column, setColumn] = useState<ColumnsType<IngredientDataType>>([]);

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const data = await selectorItemsApi.getItemList('/lists/supplier');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const allKeys = [...new Set(data.flatMap(Object.keys))];

      setColumn([
        ...allKeys.map((item) => {
          return {
            title: item,
            key: item,
            dataIndex: item,
          };
        }),
        {
          title: '',
          key: 'operation',
          fixed: 'right',
          width: 100,
          align: 'center',
          render: (text, record) => (
            <div>
              <EditOutlined onClick={() => handleEdit(record)} />
            </div>
          ),
        },
      ]);

      const source = data.map((item: any, index: number) => ({
        ...item,
        id: item?.id,
      }));

      setDataSource(source);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEdit = (record: any) => {
    setSelectedItem(record);
    setIsModalVisible(true);
    form.setFieldsValue({name: record.name});
  };

  const handleDelete = async (record: any) => {
    await selectorItemsApi.deleteItemList('/lists/supplier', record.id);
    fetchItems();
  };

  if (loading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setConfirmLoading(true);

        await selectorItemsApi.setItemList('/lists/supplier', values.name);
        setConfirmLoading(false);
        setIsModalVisible(false);
        form.resetFields();
        fetchItems();
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const handleEditConfirm = async () => {
    const values = await form.validateFields();
    // TODO
    // await selectorItemsApi.editItemList('/lists/supplier', selectedItem?.id, values.name);
    setSelectedItem(null);
    setIsModalVisible(false);
    form.resetFields();
    fetchItems();
  };

  return (
    <div>
      <Modal
        confirmLoading={confirmLoading}
        title={selectedItem ? `Edit supplier` : `Add new supplier`}
        onOk={selectedItem ? handleEditConfirm : handleOk}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label="Supplier name" name="name" rules={[{ required: true, message: 'Please input name' }]}>
                <Input placeholder="Enter supplier name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </Modal>

      <div style={{
        marginLeft: 50,
        marginRight: 50,
        marginTop: 30,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h2 style={{marginBottom: 16}}>Suppliers</h2>
        <Button type="primary" onClick={() => setIsModalVisible(true)}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          Add Supplier
          <PlusOutlined style={{marginLeft: 20, fontSize: 16}}/>
        </Button>
      </div>

      <div style={{marginLeft: 50, marginRight: 50, marginTop: 20}}>
        <Table columns={column} pagination={false} dataSource={dataSource} loading={loading}/>
      </div>
    </div>
  );
};

export default Providers;
