import {apiClient} from './apiClient';
import {SelectorItems} from './selectorItems';

export const selectorItemsApi = {
  getItemList: async (endpoint: string): Promise<SelectorItems> => {
    try {
      const response = await apiClient.get<SelectorItems>(endpoint);

      if (!Array.isArray(response.data)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Object.keys(response.data).map((key) => ({name: response.data[key], id: ''}));
      }

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  setItemList: async (endpoint: string, value: string): Promise<SelectorItems> => {
    try {
      const response = await apiClient.post<SelectorItems>(endpoint, {name: value});

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  setSelectItemList: async (endpoint: string, values: any): Promise<SelectorItems> => {
    try {
      const response = await apiClient.post<SelectorItems>(endpoint, values);

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  editItemList: async (endpoint: string, item: any, value: string): Promise<SelectorItems> => {
    try {
      // TODO
      const response = await apiClient.post<SelectorItems>(endpoint, {name: value});

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  deleteItemList: async (endpoint: string, value: string): Promise<SelectorItems> => {
    try {
      // TODO
      const response = await apiClient.delete<SelectorItems>(endpoint);

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
};
