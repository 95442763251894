// SectionTitle.tsx
import React from 'react';
import { Divider } from 'antd';

interface SectionTitleProps {
    title: string;
    isSubtitle?: boolean;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, isSubtitle = false }) => {
    const dividerStyle = isSubtitle
        ? {
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '24px',
            marginTop: '24px',
        }
        : {
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '24px',
            marginTop: '24px',
        };

    return (
        <Divider
            orientation="left"
            orientationMargin={0}
            style={dividerStyle}
        >
            {title}
        </Divider>
    );
};

export default SectionTitle;
