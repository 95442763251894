export interface Option {
  value: string;
  label: string;
}

export interface FieldConfig {
  id?: string;
  label: string;
  name: string; // key
  obligatory: boolean;
  disabled: boolean;
  type:
    | 'textInputNumber'
    | 'textInputString'
    | 'checkbox'
    | 'selector'
    | 'textInputNumberQuantitySelector'
    | 'checkboxListOneOption';
  options?: string[];
  endpoint?: string; // endpoint for GET and POST for getting or creating a new option for the list (only for type selector)
  postEndpoint?: string; // endpoint for POST for creating a new option for the list (only for type selector)
  postExtraValues?: any;
  tooltip?: string;
  unit?: string;
  defaultValue?: string;
  placeholder?: string;
  canDoPostRequest?: boolean;
  step?: number;
  cursive?: boolean;
}

export interface Section {
  title: string;
  permission: string;
  name?: string;
  isSubtitle?: boolean;
  canAddRows?: boolean;
  fields: FieldConfig[];
  fieldsEndpoint?: string;
  itemsSizeInRow?: number;
}

export interface FormConfig {
  form: {
    sections: Section[];
  };
}

export const formConfig: FormConfig = {
  form: {
    sections: [
      {
        title: 'Ingredient',
        fields: [
          {
            label: 'ID',
            obligatory: true,
            disabled: false,
            type: 'textInputString',
            name: 'umbrellaId',
          },
          {
            label: 'Description',
            obligatory: true,
            disabled: false,
            type: 'textInputString',
            name: 'name',
          },
          {
            label: 'Locked',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'locked',
          },
          {
            label: 'Claim',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/claim',
            name: 'claim',
            canDoPostRequest: true,
          },
          {
            label: 'UnitsClaim',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/unit_claim',
            name: 'unitClaim',
            canDoPostRequest: false,
          },
          {
            label: 'Functionality',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/functionality',
            name: 'functionality',
            canDoPostRequest: true,
          },
          {
            label: 'Declarable ING',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'declarableClaim',
          },
          {
            label: 'Type Quantity',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/type_quantity',
            name: 'typeQuantity',
            canDoPostRequest: false,
          },
          {
            label: 'Unit Base',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/unit_base',
            name: 'unitBase',
            canDoPostRequest: false,
          },
          {
            label: '',
            obligatory: false,
            disabled: false,
            type: 'checkboxListOneOption',
            endpoint: '/lists/type',
            name: 'type',
            canDoPostRequest: false,
          },
        ],
        permission: 'ingredient',
      },
      {
        title: 'Laboratory',
        fields: [
          {
            label: 'Bulk Density',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            unit: 'g/ml',
            name: 'bulkDensity',
          },
          {
            label: 'Humidity',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'humidity',
          },
          {
            label: 'Solubility',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/L',
            name: 'solubility',
          },
          {
            label: 'Appearance',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'appearance',
          },
          {
            label: 'Refractive Index',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'regractiveIndex',
          },
          {
            label: 'Polarimetry',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '°',
            name: 'polarimetry',
          },
          {
            label: 'Identification',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/identification',
            canDoPostRequest: false,
            name: 'identification',
          },
          {
            label: 'Flash Point',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'º C',
            name: 'flashPoint',
          },
          {
            label: 'Alcoholic Grade',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'º',
            name: 'alcoholicGrade',
          },
          {
            label: 'Particle Size <200 ug',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'particleSize200',
          },
          {
            label: 'Particle Size <355 ug',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'particleSize355',
          },
          {
            label: 'Particle Size <500 ug',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'particleSize500',
          },
          {
            label: 'Residual Ignition',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'residualIgnition',
          },
          {
            label: 'pH',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'ph',
          },
        ],
        permission: 'laboratory',
      },
      {
        title: 'According to',
        permission: 'according',
        isSubtitle: true,
        fieldsEndpoint: '/lists/according',
        fields: [],
        name: 'productAccordingTo',
        itemsSizeInRow: 6,
      },
      {
        title: '',
        permission: '',
        isSubtitle: true,
        itemsSizeInRow: 6,
        fields: [
          {
            label: 'Annex III',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'annex3',
          },
          {
            label: 'BIO',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'bio',
          },
          {
            label: 'Kosher',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'kosher',
          },
          {
            label: 'Halal',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'halal',
          },
        ],
      },
      {
        title: 'Allergens',
        permission: 'allergens',
        isSubtitle: true,
        fieldsEndpoint: '/lists/allergens',
        fields: [],
        name: 'productAllergens',
        itemsSizeInRow: 6,
      },
      {
        title: 'Nutritional Values',
        permission: 'nutritional',
        isSubtitle: true,
        itemsSizeInRow: 13,
        fields: [
          {
            label: 'Energy (Kcal)',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'Kcal',
            name: 'kcal',
          },
          {
            label: 'Energy (KJ)',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'KJ',
            name: 'kj',
          },
          {
            label: 'Protein',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'protein',
          },
          {
            label: 'Carbohydrate',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'carbohydrate',
          },
          {
            label: 'of which sugars',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'carbohydrateSugars',
          },
          {
            label: 'of which polyols',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'carbohydratePolyols',
          },
          {
            label: 'of which starch',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'carbohydrateStarch',
          },
          {
            label: 'Fats',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'fats',
          },
          {
            label: 'of which saturates',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'fatsSaturates',
          },
          {
            label: 'of which mono-unsat',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'fatsMonoUnsat',
          },
          {
            label: 'of which poly-unsat',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'fatsPolyUnsat',
          },
          {
            label: 'of which trans-fats',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'fatsTransFats',
          },
          {
            label: 'cholesterol',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'mg/100g',
            name: 'fatsCholesterol',
          },
          {
            label: 'Dietary Fibre',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'dietaryFibre',
          },
          {
            label: 'Sodium',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'sodium',
          },
          {
            label: 'Salt',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'g/100g',
            name: 'salt',
          },
        ],
      },
      {
        title: 'Purchases',
        permission: 'purchase',
        canAddRows: true,
        name: 'purchases',
        itemsSizeInRow: 4,
        fields: [
          {
            label: 'Quantity',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'Kg',
            name: 'quantity',
          },
          {
            label: 'Price',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'price',
          },
          {
            label: 'units',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'unit',
          },
          {
            label: 'Supplier',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/supplier',
            name: 'supplier',
            canDoPostRequest: true,
          },
          {
            label: 'Cod. Supplier',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'codSupplier',
          },
          {
            label: 'Brand',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/brand',
            name: 'brand',
            canDoPostRequest: true,
          },
        ],
      },
      {
        title: 'R&D+i',
        permission: 'rdi',
        fields: [
          {
            label: 'Cod. Category ProductPayload',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            defaultValue: 'MMPP',
            name: 'codCategory',
          },
          {
            label: 'Internal Name',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'internalName',
          },
          {
            label: 'Brand',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/brand',
            name: 'brand',
            canDoPostRequest: true,
          },
          {
            label: 'Encapsulated',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'encapsulated',
          },
          {
            label: 'Chelated',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'chelated',
          },
          {
            label: 'Passivated',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'passivated',
          },
          {
            label: 'Adduction',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'adduction',
          },
          {
            label: 'Protein/Peptides',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'proteinPeptides',
          },
          {
            label: 'Water',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'water',
          },
          {
            label: 'Sea Water',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'seaWater',
          },
          {
            label: 'Probiotic',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'probiotic',
          },
          {
            label: 'Prebiotic',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'prebiotic',
          },
          {
            label: 'Enzim',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'enzim',
          },
          {
            label: 'Fibre',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'fibre',
          },
          {
            label: 'Plant Extract',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'plantExtract',
          },
          {
            label: 'Without Actives (Family)',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'withoutActives',
          },
        ],
      },
      {
        title: 'Actives',
        permission: 'active',
        name: 'actives',
        isSubtitle: true,
        itemsSizeInRow: 7,
        fields: [
          {
            label: 'MAIN Active name',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/active',
            postEndpoint: '/active',
            name: 'mainActiveName',
            canDoPostRequest: true,
            postExtraValues: {
              vrn: 0,
              vrnUnit: "mg",
              iuClaim: 0,
              ul: 0,
              ulUnit: "mg/day"
            }
          },
          {
            label: 'MAIN Active value',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'mainActiveValue',
            unit: '%',
            step: 0.000001,
          },
          {
            label: 'Claim Declareable',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'mainActiveDeclarable',
          },
        ],
      },
      {
        title: '',
        permission: '',
        name: 'actives',
        isSubtitle: true,
        canAddRows: true,
        itemsSizeInRow: 7,
        fields: [
          {
            label: 'Active name',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/active',
            postEndpoint: '/active',
            name: 'activeName',
            canDoPostRequest: true,
            postExtraValues: {
              vrn: 0,
              vrnUnit: "mg",
              iuClaim: 0,
              ul: 0,
              ulUnit: "mg/day"
            }
          },
          {
            label: 'Active value',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: '%',
            name: 'activeValue',
            step: 0.000001,
          },
          {
            label: 'Claim Declareable',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'declarable',
          },
        ],
      },
      {
        title: 'Quality',
        permission: 'quality',
        fields: [
          // {
          //   label: '100% VRN',
          //   obligatory: false,
          //   disabled: false,
          //   type: 'textInputNumber',
          //   options: ['g/day', 'mg/day', 'ug/day'],
          //   name: 'vrn',
          // },
          // {
          //   label: '[I.U.] Claim',
          //   obligatory: false,
          //   disabled: false,
          //   type: 'textInputString',
          //   unit: 'I.U.',
          //   name: 'iuClaim',
          // },
          // {
          //   label: 'UL',
          //   obligatory: false,
          //   disabled: false,
          //   type: 'textInputNumberQuantitySelector',
          //   options: ['g/day', 'mg/day', 'ug/day'],
          //   name: 'ul',
          // },
          {
            label: 'Solids Limits',
            obligatory: false,
            disabled: false,
            type: 'textInputNumberQuantitySelector',
            options: ['g/Kg', 'mg/Kg', 'ug/Kg'],
            name: 'limitSolids',
          },
          {
            label: 'Liquid Limits',
            obligatory: false,
            disabled: false,
            type: 'textInputNumberQuantitySelector',
            options: ['g/L', 'mg/L', 'ug/L'],
            name: 'limitLiquids',
          },
          {
            label: 'Syrup/Gel Limits',
            obligatory: false,
            disabled: false,
            type: 'textInputNumberQuantitySelector',
            options: ['g/Kg', 'mg/Kg', 'ug/Kg'],
            name: 'limitSyrups',
          },
          {
            label: 'RDI (Rec. Daily Intake)',
            obligatory: false,
            disabled: false,
            type: 'textInputNumberQuantitySelector',
            options: ['g/day', 'mg/day', 'ug/day'],
            name: 'recDairyIntake',
          },
          {
            label: 'List of Ingredients',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'listOfIngredients',
          },
          {
            label: 'Latin Name',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'latinName',
            cursive: true,
          },
          {
            label: 'Valorated Extract',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'valoratedExtract',
          },
          {
            label: 'Origin',
            obligatory: false,
            disabled: false,
            type: 'selector',
            endpoint: '/lists/origin',
            canDoPostRequest: false,
            name: 'origin',
          },
          {
            label: 'E-Number',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'eNumber',
          },
        ],
      },
      {
        title: 'Microbiology',
        permission: 'microbiology',
        isSubtitle: true,
        itemsSizeInRow: 13,
        fields: [
          {
            label: 'Mesophilic aerobics',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'cfu/g',
            name: 'mesophilicAerobics',
          },
          {
            label: 'T. Enterobacteriaceae',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'enterobacteriaceae',
            unit: 'cfu/g',
          },
          {
            label: 'E. Coli',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            name: 'eColi',
            unit: '/g',
            placeholder: 'Absent',
          },
          {
            label: 'Salmonella',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'salmonella',
            unit: '/25g',
            placeholder: 'Absent',
          },
          {
            label: 'Pseudomonas',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'pseudomonas',
            unit: '/g',
            placeholder: 'Absent',
          },
          {
            label: 'Staphylococcus aureus',
            obligatory: false,
            disabled: false,
            type: 'textInputString',
            name: 'staphylococcus',
            unit: '/g',
            placeholder: 'Absent',
          },
          {
            label: 'Yeast & Moulds',
            obligatory: false,
            disabled: false,
            type: 'textInputNumber',
            unit: 'cfu/g',
            name: 'yeastAndMoulds',
          },
        ],
      },
      {
        title: 'Diet Suitability',
        permission: 'diet',
        isSubtitle: true,
        fields: [
          {
            label: 'VEGAN',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'vegan',
          },
          {
            label: 'VEGETARIAN',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'vegetarian',
          },
          {
            label: 'Gluten Free',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'glutenFree',
          },
          {
            label: 'Sugar Free',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'sugarFree',
          },
          {
            label: 'Lactose Free',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'lactoseFree',
          },
          {
            label: 'Doping Free',
            obligatory: false,
            disabled: false,
            type: 'checkbox',
            name: 'dopingFree',
          },
        ],
      },
    ],
  },
};

export const formItemMock = {
  id: 0,
  description: 'Texto de ejemplo',
  locked: true,
  ingclaim: 'Texto de ejemplo',
  functionality: 'Texto de ejemplo',
  declarableing: true,
  typequantity: 'Minority',
  unitbase: 'KG',
  bulkdensity: 'Texto de ejemplo',
  humidity: 123,
  solubility: 123,
  appearance: 'Texto de ejemplo',
  refractiveindex: 123,
  polarimetry: 123,
  'particlesize<200ug': 123,
  'particlesize<355ug': 123,
  'particlesize<500ug': 123,
  'particlesize>500ug': 123,
  identification: 'FTIR',
  flashpoint: 123,
  alcoholicgrade: 123,
  chelate: true,
  passivated: true,
  adduction: true,
  fcc: true,
  pheur: true,
  bp: true,
  usp: true,
  jp: true,
  eto: true,
  VEGETARIAN: true,
  VEGAN: false,
  gmo: true,
  'tse/bse': true,
  noradiation: true,
  heavymetalseu: true,
  pesticideseu: true,
  residualsolventseu: true,
  bio: true,
  kosher: true,
  halal: true,
  'peanuts/lupins,andtheirderivatives': true,
  'treenuts,andtheirderivatives': true,
  'cerealscontaininggluten,andtheirderivatives': true,
  'milk,anditsderivatives': true,
  'eggs,andtheirderivatives': true,
  'fish,andtheirderivatives': true,
  'shellfish&molluscs,andtheirderivatives': true,
  'soya,anditsderivatives': true,
  'sesame,anditsderivatives': true,
  'celery,anditsderivatives': true,
  'mustard,anditsderivatives': true,
  'sulphites,andtheirderivatives(>10mg/kgasso2)': true,
};

//   //Ingredient
//         $product['umbrellaId'] = $requestP->id ?? null;
//         $product['name'] = $requestP->description ?? null;
//         $product['locked'] = $requestP->Locked ?? null;
//         $product['claim'] = $requestP->{'ing claim'} ?? null;
//         $product['declarableClaim'] = $requestP->{'Declarable ING'} ?? null;
//         $product['functionality'] = $requestP->functionality ?? null;
//         $product['unitBase'] = $requestP->{'unit base'} ?? null;
//         $product['typeQuantity'] = $requestP->{'type quantity'} ?? null;
//
//         //Laboratory
//         $product['bulkDensity'] = $requestP->{'bulk density'} ?? null;
//         $product['humidity'] = $requestP->humidity ?? null;
//         $product['solubility'] = $requestP->solubility ?? null;
//         $product['identification'] = $requestP->identification ?? null;
//         $product['appearance'] = $requestP->appearance ?? null;
//         $product['flashPoint'] = $requestP->{'flash point'} ?? null;
//         $product['regractiveIndex'] = $requestP->{'refractive index'} ?? null;
//         $product['alcoholicGrade'] = $requestP->{'alcoholic grade'} ?? null;
//         $product['polarimetry'] = $requestP->polarimetry ?? null;
//         $product['chelated'] = $requestP->Chelate ?? null;
//         $product['passivated'] = $requestP->Passivated ?? null;
//         $product['adduction'] = $requestP->Adduction ?? null;
//         $product['particleSize200'] = $requestP->{'particle size <200 ug'} ?? null;
//         $product['particleSize355'] = $requestP->{'particle size <355 ug'} ?? null;
//         $product['particleSize500'] = $requestP->{'particle size <500 ug'} ?? null;
//         $product['particleSizeMore500'] = $requestP->{'particle size >500 ug'} ?? null;
//         $product['productAccordingTo'] = $requestP->according_to ?? null;
//         $product['annex3'] = $requestP->{'annex ii'} ?? null;
//         $product['bio'] = $requestP->BIO ?? null;
//         $product['kosher'] = $requestP->Kosher ?? null;
//         $product['halal'] = $requestP->Halal ?? null;
//         $product['productAllergens'] = $requestP->allergens ?? null;
//         $product['kcal'] = $requestP->{'energy (kcal)'} ?? null;
//         $product['kj'] = $requestP->{'energy (kj)'} ?? null;
//         $product['protein'] = $requestP->protein ?? null;
//         $product['carbohydrate'] = $requestP->carbohydrate ?? null;
//         $product['carbohydrateSugars'] = $requestP->{'of which sugars'} ?? null;
//         $product['carbohydratePolyols'] = $requestP->{'of which polyols'} ?? null;
//         $product['carbohydrateStarch'] = $requestP->{'of which starch'} ?? null;
//         $product['fats'] = $requestP->fats ?? null;
//         $product['fatsSaturates'] = $requestP->{'of which saturates'} ?? null;
//         $product['fatsMonoUnsat'] = $requestP->{'of which mono-unsat'} ?? null;
//         $product['fatsPolyUnsat'] = $requestP->{'of which poly-unsat'} ?? null;
//         $product['fatsTransFats'] = $requestP->{'of which trans-fats'} ?? null;
//         $product['fatsCholesterol'] = $requestP->cholesterol ?? null;
//         $product['dietaryFibre'] = $requestP->{'of which starch'} ?? null;
//         $product['sodium'] = $requestP->sodium ?? null;
//         $product['salt'] = $requestP->salt ?? null;
//
//         //Purchase
//         $product['purchases'] = $requestP->purchases ?? null;
//
//         //RDi
//         $product['codCategory'] = $requestP->{'cod. category product'} ?? null;
//         $product['internalName'] = $requestP->{'internal name'} ?? null;
//         $product['encapsulated'] = $requestP->Encapsulated ?? null;
//         $product['brand'] = $requestP->brand ?? null;
//         $product['chelate'] = $requestP->Chelated ?? null;
//         $product['proteinPeptides'] = $requestP->{'Protein/Peptides'} ?? null;
//         $product['water'] = $requestP->Water ?? null;
//         $product['seaWater'] = $requestP->{'Sea Water'} ?? null;
//         $product['probiotic'] = $requestP->Probiotic ?? null;
//         $product['prebiotic'] = $requestP->Prebiotic ?? null;
//         $product['enzim'] = $requestP->Enzim ?? null;
//         $product['fibre'] = $requestP->Fibre ?? null;
//         $product['plantExtract'] = $requestP->{'Plant Extract'} ?? null;
//         $product['mainActive'] = $requestP->{'main active'} ?? null;
//         $product['mainActiveDeclarable'] = $requestP->{'Claim Declareable'} ?? null;
//         $product['withoutActives'] = $requestP->{'without actives (family)'} ?? null;
//         //$product['withoutActivesDeclarable']
//         //$product['actives']
//
//         //Quality
//         $product['vrn'] = $requestP->vrn ?? null;
//         $product['iuClaim'] = $requestP->{'[i.u.] claim'} ?? null;
//         $product['ul'] = $requestP->ul ?? null;
//         $product['limitSolids'] = $requestP->{'limit solids'} ?? null;
//         $product['limitLiquids'] = $requestP->{'limit liquids'} ?? null;
//         $product['limitSyrups'] = $requestP->{'limit syrups/gels/emulsions'} ?? null;
//         $product['recDairyIntake'] = $requestP->{'rdi (rec. daily intake'} ?? null;
//         $product['listOfIngredients'] = $requestP->{'list of ingredients'} ?? null;
//         $product['latinName'] = $requestP->{'latin name'} ?? null;
//         $product['valoratedExtract'] = $requestP->{'valorated extract'} ?? null;
//         $product['origin'] = $requestP->origin ?? null;
//         $product['totalPlateCounty'] = $requestP->{'total plate count'} ?? null;
//         $product['eColi'] = $requestP->{'e. coli'} ?? null;
//         $product['salmonella'] = $requestP->salmonella ?? null;
//         $product['pseudomonas'] = $requestP->pseudomonas ?? null;
//         $product['staphylococcus'] = $requestP->{'staphylococcus aureus'} ?? null;
//         $product['yeastAndMoulds'] = $requestP->{'yeast & moulds'} ?? null;
//         $product['vegan'] = $requestP->VEGAN ?? null;
//         $product['vegetarian'] = $requestP->VEGETARIAN ?? null;
//         $product['glutenFree'] = $requestP->{'Gluten Free'} ?? null;
//         $product['sugarFree'] = $requestP->{'Sugar Free'} ?? null;
//         $product['lactoseFree'] = $requestP->{'Lactose Free'} ?? null;
