import React, {useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Space, Spin} from 'antd';
import {FieldConfig, formConfig, Section} from '../models/productFormData';
import {InfoCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import './ProductForm.css';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import SelectComponent from '../components/Select';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {productsApi} from '../apiCalls/productsApi';
import CheckboxList from '../components/CheckboxList';
import SectionTitle from "../components/SectionTitle";

const UNITS_INPUTS_WITH = '300px';

const EditIngredientForm: React.FC = () => {
  const [sections, setSections] = useState<Section[]>(formConfig.form.sections);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [checkboxValues, setCheckboxValues] = useState<{ [key: string]: boolean | undefined }>({});
  const [quantitySelectorValues, setQuantitySelectorValues] = useState<{ [key: string]: string | undefined }>({});
  const [unitClaimValue, setUnitClaimValue] = useState(' ');
  const [productData, setProductData] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({});
  const { id } = useParams<{ id: string }>();

  const handleCheckboxChange = (fieldName: string, checked: boolean) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [fieldName]: checked,
    }));
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const data = await productsApi.getProductById(id ?? '');
        setProductData(data);
      } catch (error) {
        console.error('Error fetching product data:', error);
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  useEffect(() => {
    const completeFields = async () => {
      try {
        const updatedSections = await Promise.all(
            sections.map(async (section) => {
              if (section.fieldsEndpoint) {
                try {
                  const fields = await selectorItemsApi.getItemList(section.fieldsEndpoint);
                  const inputs = fields.map((field) => ({
                    label: field.name,
                    name: field.name,
                    id: field.id,
                    obligatory: false,
                    disabled: false,
                    type: 'checkbox',
                  })) as FieldConfig[];

                  return { ...section, fields: section.fields.concat(inputs) };
                } catch (error) {
                  console.error(`Error fetching fields for section ${section.title}:`, error);
                  return section;
                }
              }

              return section;
            }),
        );

        setSections(updatedSections);
      } catch (error) {
        console.error('Error completing fields:', error);
      }
    };

    completeFields();
  }, []);

  useEffect(() => {
    if (productData) {
      const initialValues = { ...productData };

      if (productData.productAccordingTo?.length > 0) {
        productData.productAccordingTo.forEach((item: { id: string; name: string }) => {
          initialValues[item.name] = true;
        });
      }

      if (productData.productAllergens?.length > 0) {
        productData.productAllergens.forEach((item: { id: string; name: string }) => {
          initialValues[item.name] = true;
        });
      }

      if (productData.actives?.length > 0) {
        productData.actives.forEach((item: { id: string; declarable: boolean }, index: number) => {
          const fieldName = 'declarable' + index;
          handleCheckboxChange(fieldName, item.declarable);
        });
      }

      const quantitySelector: { [key: string]: string } = {};

      sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (field.type === 'textInputNumberQuantitySelector' && field.options) {
            quantitySelector[field.name + 'Unit'] = productData[field.name + 'Unit'];
          }
        });
      });

      setQuantitySelectorValues(quantitySelector);

      if (productData.unitClaim) {
        setUnitClaimValue(productData.unitClaim);
      }

      setInitialValues(initialValues);
      form.setFieldsValue(initialValues);
      setIsLoading(false);
    }
  }, [productData, form, sections]);

  const handleSubmit = async (values: any) => {
    const formattedValues: { [key: string]: any } = values;

    sections.forEach((section) => {
      if (section.fieldsEndpoint) {
        const sectionValues: { [key: string]: boolean } = {};

        section.fields.forEach((field) => {
          if (!field.id) return;

          sectionValues[field.id] = values[field.name];
          delete formattedValues[field.name];
        });

        if (section.name) {
          formattedValues[section.name] = sectionValues;
        }
        section.fields.forEach((field) => {
          if (!field.id) return;
          delete formattedValues[field.name];
        });
      } else if (section.fields) {
        section.fields.forEach((field) => {
          if (field.type === 'selector' && values[field.name]?.id) {
            formattedValues[field.name] = values[field.name].id;
          }
        });
      }
    });

    if (formattedValues.actives && Array.isArray(formattedValues.actives)) {
      formattedValues.actives = formattedValues.actives.map((active, index) => {
        const checkboxKey = `declarable${index}`;

        return {
          activeName: active.activeName.id ? active.activeName.id : active.activeName,
          activeValue: active.activeValue,
          declarable: checkboxValues[checkboxKey] ?? false,
          id: active.id,
        };
      });
    }

    const productId = id; // Usar el id de la URL
    const formattedValuesWithUnits = { ...formattedValues, ...quantitySelectorValues };

    try {
      await productsApi.editProduct(formattedValuesWithUnits, `${productId}`);

      notification.success({
        message: 'Operación exitosa',
        description: 'Tu producto se ha editado con éxito.',
        duration: 3,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Ocurrió un error, vuelva a intentar',
        duration: 3,
      });
    }
  };

  const renderFormItem = (sectionTitle: string, item: FieldConfig, defaultSelectValue?: string) => {
    let unit = item.unit ? item.unit : undefined;

    if (item.name === 'vrn') {
      unit = unitClaimValue;
    }

    const inputStyle =
        sectionTitle === 'Nutritional Values' || sectionTitle === 'Microbiology'
            ? { width: UNITS_INPUTS_WITH }
            : {};

    console.log('----------')
    console.log('hello item')
    console.log(sectionTitle)
    console.log(item)
    console.log(inputStyle)

    switch (item.type) {
      case 'textInputNumber':
        return (
          <Input
            type="number"
            placeholder={`Enter ${item.label}`}
            addonAfter={unit}
            disabled={item.disabled}
            min={0}
            step={item.step ?? 0.000001}
            style={inputStyle}
          />
        );
      case 'textInputNumberQuantitySelector':
        return (
          <Input
            type="number"
            placeholder={`Enter ${item.label}`}
            addonAfter={
              <Select
                onSelect={(value) => {
                  setQuantitySelectorValues((prevValues) => ({
                    ...prevValues,
                    [item.name + 'Unit']: value,
                  }));
                }}
                // defaultValue={items?.options ? items.options[0] : ''}
                defaultValue={quantitySelectorValues[item.name + 'Unit']}
                style={{width: 90}}
              >
                {item?.options?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            }
            disabled={item.disabled}
          />
        );
      case 'textInputString':
        return (
          <Input
            placeholder={`Enter ${item.label}`}
            addonAfter={item.unit ? item.unit : undefined}
            disabled={item.disabled}
            style={{
              fontStyle: item.cursive ? 'italic' : undefined,
              ...inputStyle
            }}
          />
        );
      case 'checkbox':
        return (
            <Checkbox>{item.label}</Checkbox>
        );
      case 'selector':
        return (
          <SelectComponent
            title={item?.label}
            onChange={(value) => {
              if (item.name === 'unitClaim') {
                setUnitClaimValue(value);
              }
            }}
            defaultValue={defaultSelectValue ? defaultSelectValue : productData?.[item.name] ?? ''}
            endpoint={item.endpoint}
            postEndpoint={item.postEndpoint}
            postExtraValues={item.postExtraValues}
            canDoPostRequest={item.canDoPostRequest}
          ></SelectComponent>
        );
      case 'checkboxListOneOption':
        return <CheckboxList name={item.name} form={form} endpoint={item.endpoint!}></CheckboxList>;
    }
  };

  const renderForm = (sectionTitle: string, item: FieldConfig, itemsSizeInRow = 8) => {
    return (
      <Col key={item.label} xs={24} sm={12} md={itemsSizeInRow} lg={itemsSizeInRow}>
        <Form.Item
          className={item.type === 'checkbox' ? 'custom-checkbox-item' : 'custom-form-item'}
          style={{ marginBottom: item.type === 'checkbox' ? '8px' : '12px' }}
          label={item.type === 'checkbox' ? ' ' : item.label}
          name={item.type === 'checkbox' ? undefined : item.name}
          tooltip={
            item.tooltip
              ? {
                  title: item.tooltip,
                  icon: <InfoCircleOutlined style={{marginLeft: 10, color: 'black'}} />,
                }
              : undefined
          }
        >
          {renderFormItem(sectionTitle, item)}
        </Form.Item>
      </Col>
    );
  };

  const renderFormCanAddRow = (
    section: Section,
    dynamicFields: any[],
    add: () => void,
    remove: (index: number) => void,
  ) => {
    const size = section.itemsSizeInRow ? section.itemsSizeInRow : 11;

    return (
      <>
        {dynamicFields.map((dynamicField, index) => (
          <div style={{display: section.itemsSizeInRow ? 'flex' : 'flex'}} key={dynamicField.key}>
            <Row style={{width: '100%'}} gutter={16} key={dynamicField.key}>
              {section.fields.map((field) => {
                const fieldName = field.type === 'checkbox' ? field.name + index : field.name;

                let defaultSelectValue = '';

                const purchases = productData?.purchases;

                if (purchases && section.title === 'Purchases' && field.type === 'selector') {
                  if (purchases.length > index) {
                    defaultSelectValue = purchases[index][field?.name]?.name;
                  }
                }

                const actives = productData?.actives;

                if (actives && section.name === 'actives' && field.type === 'selector') {
                  if (actives.length > index) {
                    defaultSelectValue = actives[index][field?.name]?.name;
                  }
                }
                return (
                  <Col xs={24} sm={12} md={size} lg={size} key={field.label}>
                    <Form.Item
                      style={{height: 50}}
                      label={field.label + (section.name === 'actives' ? ' #' + (index + 1) : '')}
                      name={field.type === 'checkbox' ? [dynamicField.name, fieldName] : [dynamicField.name, fieldName]}
                      fieldKey={[dynamicField.fieldKey, field.label.toLowerCase()]}
                    >
                      {field.type === 'checkbox' ? (
                        <Checkbox
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          checked={checkboxValues[fieldName] || false}
                          onChange={(e) => handleCheckboxChange(fieldName, e.target.checked)}
                        />
                      ) : (
                        renderFormItem(field.label, field, defaultSelectValue)
                      )}
                    </Form.Item>
                  </Col>
                );
              })}
              <Divider
                orientationMargin={0}
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
              ></Divider>
            </Row>

            <MinusCircleOutlined style={{marginLeft: 10}} onClick={() => remove(index)} />
          </div>
        ))}

        <Form.Item style={{height: 50, marginTop: 10}}>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add Row
          </Button>
        </Form.Item>
      </>
    );
  };

  if (isLoading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  return (
    <div className={'container'}>
      <h2 style={{marginBottom: 50, marginLeft: -5}}>Edit Ingredient</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
        // initialValues={{VEGETARIAN: true, id: 123}}
      >
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            {section.title && (
                <SectionTitle title={section.title} isSubtitle={section.isSubtitle} />
            )}

            {section.canAddRows ? (
              <Form.List name={section.name ?? section.title}>
                {(fields, {add, remove}) => renderFormCanAddRow(section, fields, add, remove)}
              </Form.List>
            ) : (
              <Row gutter={16}>{section.fields.map((field) => renderForm(section.title, field, section.itemsSizeInRow))}</Row>
            )}
          </div>
        ))}
        <Space style={{marginTop: 16, marginBottom: 30}}>
          <Button type="primary" htmlType="submit">
            Save Ingredient
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default EditIngredientForm;
